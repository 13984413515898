@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.ingredientsWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-core-6);
}

.ingredientWrapper {
    display: flex;
}

.ingredientText {
    word-break: break-word;
}

.checkbox {
    display: inline-block;
    height: var(--sizing-core-4);
    margin-top: var(--spacing-core-1);
    margin-right: var(--spacing-core-4);
    border: none;
    padding: 0;
    background: inherit;
}

.image {
    width: var(--sizing-core-4);
    height: var(--sizing-core-4);
    cursor: pointer;
    outline: none;
}

@include mq.mq($from: medium) {
    .ingredients {
        padding: var(--spacing-core-8) var(--spacing-core-12);
    }
}


